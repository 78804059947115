.navbar{
    background: black;
    color: white;
    position: fixed;
    width: 100%;
}

.photo{
    border-radius: 50%;
    width: 10%;
    height: 20%;
}

#Navbar {
    width: 20%;
    flex-direction:column;
    align-items: end;
}