.responsive{
    width: 100%;
    height: 100%;
    object-fit:cover;
}


@media (min-width: 1200px){
    .responsive {
      height: 690px;
    }
}
@media (max-width: 600px){
    .intro {
        font-size: 50px;
    }
}
.intro{
    text-align: center;
    color: #6F4E37;
    font-family: "Brush Script MT";
    font-size: 150px;
}

.para{
    font-family: inherit;
    color: #6e6e30;
}
.sec{
    flex-wrap: wrap;
    margin-top: 30px;
}
.common{
    margin: 10px 20px;
    width: 300px;
    color:white;
}
.a{
    background-color: #231709;   
}
.b{
    background-color: #6e6e30;
}
.c{
    background-color: #9A7B4F;
}

