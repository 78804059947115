.last{
    background-color: #6e6e30;
}
.first > img{
    width: 100%;
    height: 100%;
}
.secondpart{
    background-color: #6e6e30;
    color: white;
    display: flex;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    font-family: inherit;
}

.third>img{
    width: 100%;
}